.single{
    .nav-index.single{
        background-color: white;
        color: black;

        .nav-index__links{
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }

        .nav-index__link:first-child, .nav-index__link:nth-child(2), .nav-index__link:nth-child(3) {
            border-right: 1px solid #000;
        }
    }

    .template-single{
        margin-top: 50px;
        &__title-project{
            border-top: 0px solid rgba(0,0,0,0.5);
            padding-top: 1px;
            margin-bottom: 30px;
            @media(max-width: 768px){
                margin-bottom: 20px;
            }

            @media(max-width: 540px){
                margin-bottom: 20px;
            }

            @media(max-width: 470px){
                margin-bottom: 20px;
            }
            
            h2{
                font-size: $fz-60;
                opacity: 0.5;
                margin: 0;
                @media(max-width: 768px){
                    font-size: 50px;
                }
    
                @media(max-width: 540px){
                    font-size: 40px;
                }
    
                @media(max-width: 470px){
                    font-size: 25px;
                    line-height: 26px;
                }
            }

            h1{
                font-size: $fz-60;
                margin: 25px 0 0;
                line-height: 0.9;
                @media(max-width: 768px){
                    font-size: 50px;
                }
    
                @media(max-width: 540px){
                    font-size: 40px;
                }
    
                @media(max-width: 470px){
                    font-size: 25px;
                    line-height: 26px;
                }
            }
        }

        &__data{
            border-top: 1px solid rgba(0, 0, 0, 0.5);
            margin-bottom: 120px;
            @media($media-mobile){
                margin-bottom: 30px;
            }
            .four-col{
                display: flex;
                flex-flow: wrap;
                width: 100%;
                @media($media-mobile){
                    width: 100%;
                }
                &__item{
                    width: calc(12.5vw - 7.5px);
                    padding: 16px 0;
                    margin-right: 25px;
                    @media($media-mobile){
                        width: calc(40vw - 7.5px);
                    }
                    h3{
                        
                        font-size: $fz-18;
                        margin-bottom: 12px;
                        opacity: 0.5;
                        @media(max-width: 470px){
                            font-size: 16px;
                        }
                    }
                    p{
                        
                        font-size: $fz-18;
                        @media(max-width: 470px){
                            font-size: 16px;
                        }
                    }
                }
    
            }

        }

        &__content{
            margin-bottom: 50px;
            @media($media-mobile){
                margin-bottom: 30px;
            }
            .two-col{
                display: block;
                columns: 3;
                column-gap: 30px;
                @media(max-width: 990px){
                    columns: 2;
                    column-gap: 30px;
                }

                @media(max-width: 540px){
                    columns: 1;
                    column-gap: 30px;
                }
                &__item{
                    h1,h2{
                        font-size: $fz-60;
                        @media(max-width: 768px){
                            font-size: 50px;
                        }
            
                        @media(max-width: 540px){
                            font-size: 40px;
                        }
            
                        @media(max-width: 470px){
                            font-size: 25px;
                        }
                    }
                    h3{
                        font-size: $fz-35;
                        @media(max-width: 768px){
                            font-size: 25px;
                        }
            
                    }
                    p{
                        font-size: 15px;
            
                        @media(max-width: 540px){
                            font-size: 15px;
                        }
            
                    }
                }

                @media($media-mobile){
                    display: block;
                }
            }
            
        }

        &__related{
            border-top: 1px solid rgba(0,0,0,0.5);
            margin-bottom: 135px;
            h3{
                margin-top: 16px;
                font-size: $fz-25;
                margin-bottom: 35px;
            }

            .template-home{

                &__element{
                    height: 300px;

                    img{
                        width: auto;
                        min-height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 15px;
                        transition: all .3s ease;
                        
                    }

                    p{
                        font-size: $fz-18;
                        line-height: $fz-18;
                        opacity: 0.5;
                        margin-top: 12px;
                        color: white;
                        @media(max-width: 540px){
                            font-size: 18px;
                            margin-top: 5px;
                        }
            
                    }    
                    h3{
                        font-size: $fz-25;
                        line-height: $fz-25;
                        margin-bottom: 0;
                        margin-top: 5px;
                        @media(max-width: 540px){
                            font-size: 18px;
                            margin-bottom: 0px;
                            margin-top: 0px;
                        }
        
                    }
                }
            }


        }

        &__carrousel{
            margin-bottom: 75px;
            position: relative;
            @media($media-mobile){
                display: none;
            }
            .swiper{
                height: 80vh;
            }

            .swiper-slide {
                flex-shrink: 0;
                width: auto;
                height: 100%;
                position: relative;
                transition-property: transform;
                img, video{
                    height: 100%;
                    width: auto;
                }
            }

            .swiper-button{
                position: absolute;
                top: calc(50%);
                left: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                transform: translateY(-50%);
                z-index: 9;
                .arrow{
                    width: auto;
                    height: 35px;
                }

                .swiper-button-prev {
                    width: 76px;
                    height: 35px;
                    font-size: 23px;
                    background-color: white;
                    border: 1px solid black;
                    border-radius: 60px;
                    left: 25px;
                    right: auto;

                    color: black;
                }

                .swiper-button-next:after, .swiper-button-prev:after{
                    display: none;
                }

                .swiper-button-next {
                    width: 76px;
                    height: 35px;
                    font-size: 23px;
                    background-color: white;
                    border: 1px solid black;
                    border-radius: 60px;

                    right: 25px;
                    left: auto;
                    color: black;
                }
            }
        }

        &__carrousel-mobile{
            display: none;
            @media($media-mobile){
                display: block;
            }

            .slide{
                width: 100%;
                height: auto;
                position: relative;
                transition-property: transform;
                img, video{
                    display: block;
                    width: 100%;
                    margin: 20px auto;
                }
            }
        }


    }
}