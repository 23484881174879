/****************************************************************
/ PARALLAX DECORATORS
/****************************************************************/


.deco-parallax {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;

    height: 30rem;

    transition: all 0.1s ease;
    transition-duration:0.1s;
    -webkit-transition-duration:0.1s;
    -ms-transition-duration:0.1s;
    -moz-transition-duration:0.1s;

}

.deco-parallax.top {
    background-position: top;
    top: -1rem;
}

.deco-parallax.bottom {
    background-position: bottom;
    bottom: 13rem;
}

.deco-parallax.bottom-chill {
    bottom: -4rem;
}

.deco-parallax.mid {
    background-position: center;
    top: calc(50% - 15rem);
}

.deco-parallax.right { right: 2rem; }
.deco-parallax.left { left: 2rem; }
.deco-parallax.farright { right: -2rem; }
.deco-parallax.farleft { left: 0; }
.deco-parallax.center { left: calc(50% - 2.45rem); }

.deco-parallax.smileys, .deco-parallax.warning { width: 4.9rem; }
.deco-parallax.arrows { width: 5rem; }
.deco-parallax.colors { width: 2.08rem; }

.deco-parallax.relative{
    position: relative;
    top: 0;
}
