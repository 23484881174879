.home{
    header{
        display: none !important;
    }

    .nav{
        display: flex;
        justify-content: space-between;
        padding: 15px 0px;
        width: 100%;
        top: -1px;
        background-color: white;
        position: sticky;
        z-index: 10;
        &__logo{
            font-size: 60px;
            @media(max-width: 768px){
                font-size: 50px;
            }

            @media(max-width: 540px){
                font-size: 40px;
            }

            @media(max-width: 470px){
                font-size: 25px;
            }
        }

        &__links{
            display: flex;
            align-items: center; 
            font-size: $fz-25;
            @media(max-width: 540px){
                font-size: $fz-18;
            }

            @media(max-width: 470px){
                font-size: 16px;
            }
        }

        &__link{
            margin-left: 8px;
            padding: 10px 10px;
            border: 1px solid black;
            font-size: $fz-25;
            text-align: center;
            min-width: 150px;
            box-sizing: border-box;
            border-radius: 60px;
            @media(max-width: 540px){
                padding: 5px 10px;
                min-width: auto;
                font-size: $fz-18; 
            }
        }

        &__link.lang{
            min-width: auto;
        }

        &__link.active{
            background-color: #000000;
            color: white; 
        }
    }

    .nav-index-sub{
        padding: 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px;
        width: 100%;
        top: 77px;
        background-color: white;
        position: sticky;
        z-index: 11;

        @media(max-width: 540px){
            top: 66px;
        }

        &__links{
            display: flex;
            align-items: center; 
            font-size: $fz-18;
            justify-content: space-evenly;
            width: 100%;
            padding: 6px 0px;
            border-top: 0px solid #000;
            border-bottom: 1px solid #000;
            @media(max-width: 540px){
                font-size: 16px;
            }

            @media(max-width: 470px){
                font-size: 14px;
            }
        }

        &__link{
            width: 100%;
            margin-left: 0px;
            padding: 6px 0px;
            text-align: center;
            position: relative;
            cursor: pointer;

            @media(max-width: 540px){
                padding: 6px 5px;
            }

            @media(max-width: 470px){
                padding: 6px 2px;
            }
        }

        &__link:first-child,{
        // &__link:first-child {
            border-right: 1px solid #000;
        }

        &__link:nth-child(2){
            border-right: 0px solid #000;
        }

        &__link.active:before{
            content: ' \25CF';
            font-size: 18px;
            margin-right: 5px;
            @media(max-width: 990px){
                font-size: 16px;
                margin-right: 3px;
            }
        }
    }

    .nav-index{
        display: flex;
        justify-content: space-between;
        padding: 10px 0px 0px;
        width: 100%;
        top: 0px;
        background-color: white;
        position: sticky;
        z-index: 10;
        &__links{
            display: flex;
            align-items: center; 
            font-size: $fz-25;
            justify-content: space-evenly;
            width: 100%;
            padding: 6px 0px;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            @media(max-width: 540px){
                font-size: $fz-18;
            }

            @media(max-width: 470px){
                font-size: 16px;
            }
        }

        &__link{
            width: 100%;
            margin-left: 0px;
            padding: 11px 0px;
            text-align: center;
            position: relative;
            cursor: pointer;

            @media(max-width: 540px){
                padding: 11px 0px;
            }

            @media(max-width: 470px){
                padding: 11px 0px;
            }
        }

        &__link:first-child, &__link:nth-child(2), &__link:nth-child(3){
        // &__link:first-child {
            border-right: 1px solid #000;
        }

        &__link.active:before{
            content: ' \25CF';
            font-size: 25px;
            margin-right: 5px;
            @media(max-width: 990px){
                font-size: 16px;
                margin-right: 3px;
            }
        }
    }
}

main{
    padding: 0 30px;
    @media ($media-mobile){
        padding: 0 15px;      
    }

    table{
        width: 100%;
        font-size: $fz-18;
        border-spacing: 15px;
        margin-bottom: 40px;
        border-collapse: collapse; 
        @media(max-width: 540px){
            font-size: 14px;
        }

        @media(max-width: 470px){
            font-size: 12px;
        }
        .image{
            display: none;
            position: absolute;
            width: 200px;
            height: auto;
            transform: translate(-50%, -50%);
            transition: 0.1s ease;
        }

        thead{
            
            tr{
                position: sticky;
                background-color: white;
                box-shadow: inset 0 0px 0 #000000, inset 0 -1px 0 #000000;
                z-index: 9;
            }

            th{
                padding: 15px 30px 15px 0;
                opacity: 0.5;
                text-align: -webkit-left;
            }



            .year, .sectors{
                @media ($media-mobile){
                    display: none;     
                }
            }
        }
        tbody{
            
            tr{
                border-bottom: 1px solid #000;
                
                td{
                    padding: 15px 15px 15px 0;
                    word-break: break-word;
                    ul{
                        li{
                            word-break: break-word;
                        }
                    }
                }

                .services{
                    min-width: 150px;
                }

                .year, .sectors{
                    @media ($media-mobile){
                        display: none;     
                    }
                }

            }

            tr:last-child{
                border: 0;
            }
        }
    }

    .template-home{

        &__featuredview{
            padding: 30px 0;
            p{
                font-size: $fz-18;
                line-height: $fz-18;
                opacity: 0.5;
                z-index: 1;
                position: relative;
                color: white;
                @media(max-width: 1024px){
                    font-size: $fz-18;
                    line-height: $fz-18;
                }

                @media(max-width: 470px){
                    font-size: 16px;
                    line-height: 16px;
                }
            }    
            h3{
                font-size: $fz-25;
                line-height: $fz-25;
                z-index: 1;
                position: relative;
                @media(max-width: 1024px){
                    font-size: $fz-18;
                    line-height: $fz-18;
                }
            }
        }

        &__playlist.grid-2{
            padding: 20px 0;
            h4{
                font-size: 18px;
                margin: 0 0 20px;
            }
            .playlist-list{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin-bottom: 50px;
                margin-top: 30px;
                @media(max-width: 470px){
                    grid-template-columns: 1fr; 
                    margin-bottom: 50px;
                    margin-top: 0px;
                }
            } 
        }

        &__playlist{
            padding: 0px 0 0;

            .top-element{
                display: none;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 30px;
                margin-bottom: 30px; 
                @media(max-width: 540px){
                    grid-template-columns: 1fr 1fr; 
                }

                @media(max-width: 470px){
                    grid-template-columns: 1fr; 
                }

                .title{
                    h3{
                        font-size: 35px;
                        @media(max-width: 768px){
                            font-size: 25px;
                        }
                    }
                }

                .content{
                    grid-column: span 2;
                    width: 80%;
                    @media(max-width: 768px){
                        width: 100%;            
                    }
                    p{
                        font-size: 25px;
                        @media(max-width: 768px){
                            font-size: 18px;
                        }
                    }
                }
            }


            .playlist-list{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 30px;
                margin-bottom: 50px;
                margin-top: 30px;
                @media(max-width: 540px){
                    grid-template-columns: 1fr 1fr; 
                    grid-gap: 15px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                }

                @media(max-width: 470px){
                    grid-template-columns: 1fr; 
                    grid-gap: 10px;
                }

                .playlist-element:hover{
                    img{
                        opacity: 1;
                    }

                    .image-title{
                        img{
                            opacity: 1;
                        }
                    }
                }
                .playlist-element{
                    height: 500px;
                    display: grid;
                    align-content: center;
                    justify-content: center;
                    text-align: center;
                    border-radius: 12px;
                    cursor: pointer;
                    position:relative;
                    grid-template-columns: 100%;
                    img{
                        position: absolute;
                        object-fit: cover;
                        height: 100%;
                        top: 0px;
                        left: 0;
                        min-width: 100%;
                        border-radius: 12px;
                        opacity: 0;
                        transition: all 0.3s ease;
                    }
                    

                    @media(max-width: 1280px){
                        height: 350px;
                    }

                    @media(max-width: 768px){
                        height: 250px;
                    }

                    .image-title{
                        align-self: center;
                        position: relative;
                        img{
                            position: absolute;
                            object-fit: cover;
                            height: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            min-width: 100%;
                            border-radius: 12px;
                            opacity: 0; 
                            transition: all 0.3s ease;
                        }

                        img.active{
                            opacity: 1;
                        }

                        h3{
                            font-size: $fz-35;
                            position: relative;
                            z-index: 1;
                            padding: 10px 30px;
                            @media(max-width: 768px){
                                font-size: 25px;
                                padding: 0 10px;
                            }
                        }

                    }
                    
                }
            }
        }

        &__playlist-projects{
            transition: all 0.3s ease;
            .content-playlist{
                margin: 30px 0;
                padding: 30px 0;
                border-radius: 12px;
            }

            .header-playlist{
                
                padding: 10px 0;
                
                .line{
                    height: 1px;
                    background-color: black;
                    padding: 0 10px;
                    margin-top: 10px;
                    width: calc(100% - 20px);
                    margin: 10px auto;
                }

                .list-content{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 40px;
                    align-content: center;
                    @media(max-width: 540px){
                        grid-template-columns: 1fr; 
                        grid-gap: 5px;
                    }
                    div:first-child, div:nth-child(2){
                        // border-right: 1px solid #000;
                        @media(max-width: 540px){
                            border-right: 0; 
                        }
                    }
                }

                .title-content{
                    h3{
                        font-size: $fz-60;
                        text-align: center;
                        margin: 20px auto 50px;
                        @media(max-width: 768px){
                            font-size: $fz-35; 
                            padding: 0 10px;  
                        }

                        @media(max-width: 540px){
                            font-size: $fz-35;
                            padding: 0 10px;
                        }

                    }
                }

                .description{
                    padding: 11px 20px;
                    display: grid;
                    align-content: start;
                    text-align: center;
                    p{
                        font-size: 25px; 
                        text-align: left;
                        margin-top: 10px;
                        @media(max-width: 1024px){
                            font-size: 20px;
                        }
                    }

                    h3{
                        font-size: $fz-35;
                        text-align: left;

                        @media(max-width: 768px){
                            font-size: $fz-25;   
                        }

                    }
                }

                .cita-content{
                    margin-top: 30px;
                    p{
                        font-size: 18px; 
                        text-align: center;
                        margin-top: 0;
                    }

                    h3{
                        font-size: $fz-35;
                        text-align: center;
                        width: 70%;
                        margin: auto;
                        @media(max-width: 768px){
                            font-size: $fz-25;
                            width: 90%;   
                        }


                    }
                }

                .icon{
                    padding: 11px 10px;
                    display: grid;
                    align-content: center;
                    img{
                        width: 216px;
                        height: auto;
                        display: block;
                        margin: 0 auto
                    }
                    
                }

                

            }

            .list-fo-projects{
                padding: 26px 10px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 10px;
                row-gap: 30px;
                @media(max-width: 768px){
                    grid-template-columns: 1fr 1fr; 
                }

                @media(max-width: 540px){
                    grid-template-columns: 1fr; 
                }
                li{
                    margin-top: 0px;
                    @media(max-width: 540px){
                        margin-top: 0px;
                    }
                }

                @media(max-width: 1024px){
                    padding: 0px 50px; 
                }

                @media(max-width: 768px){
                    padding: 0px 20px; 
                }

                .project{
                    width: 100%;
                    height: 250px;
                    position: relative;
                    border-radius: 15px;
                    display: grid;
                    overflow: hidden;

                    @media(max-width: 768px){
                        height: 100%;
                    }

                    .image{
                        overflow: hidden;
                        position: relative;
                        border-radius: 15px;
                        width: 100%;
                        height: 100%;
                    }

                    img{
                        min-width: 100%;
                        min-height: 100%;
                        width: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        transition: all 0.3s ease;

                        @media(max-width: 540px){
                            min-width: 100%;
                            height: auto;
                            position: relative;
                            top: unset;
                            left: unset;
                            transform: unset;
                            transition: all 0.3s ease;
                        }
                    }
        
                    .title{
                        position: absolute;
                        bottom: 20px;
                        left: 15px;
                        padding-bottom: 0;
                        opacity: 0;
                        transition: all 0.3s ease;

                        @media(max-width: 1024px){
                            opacity: 1;
                            
                        }

                        @media(max-width: 540px){
                            position: absolute;
                            bottom: 20px;
                            padding-bottom: 0;
                            
                        }
        
                        p{
                            font-size: $fz-18;
                            line-height: $fz-18;
                            opacity: 0.5;
                            z-index: 1;
                            position: relative;
                            color: white;
                            @media(max-width: 1024px){
                                font-size: $fz-18;
                                line-height: $fz-18;
                            }
            
                            @media(max-width: 470px){
                                font-size: 16px;
                                line-height: 16px;
                            }
                        }    
                        h3{
                            font-size: $fz-25;
                            line-height: $fz-25;
                            z-index: 1;
                            position: relative;
                            color: white;
                            @media(max-width: 1024px){
                                font-size: $fz-18;
                                line-height: $fz-18;
                            }
            
                        }
                    }
                }
        
                .project:hover{
                    img{
                        filter: blur(4px);

                        @media(max-width: 1024px){
                            filter: blur(0px);
                        }
        
                    }
        
                    .title{
                        opacity: 1;
                    }
                    
                }
            }

        }

        &__grid-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 15px;
            @media ($media-mobile){
                display: block;
            }
        }
        
        &__grid-related {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 15px;
            @media ($media-mobile){
                display: block;
            }

            &__element{

                a{
                    position: relative;
                }
                
                .image{
                    border-radius: 15px;
                    overflow:hidden;
                    height: auto;
                    img {
                        min-width: 100%;
                        height: auto ;
                        position: relative;
                        top: unset;
                        left: unset; 
                        transform: unset;
                        border-radius: 15px;
                        transition: all 0.3s ease;
                    }
                }

                .title{
                    align-self: end;
                    padding: 10px 20px;
                    opacity: 0;
                    transition: all 0.3s ease;
                    position: absolute;
                    bottom: 20px;
                    @media(max-width: 1024px){
                        opacity: 1;
                    }
                    h3{
                        color: white;
                    }

                    p{
                        color: white;
                        opacity: 0.5;
                    }
                }

                @media ($media-mobile){
                    margin-bottom: 20px;
                }
            }

            &__element:hover{
                img{
                    filter: blur(8px);
                    @media(max-width: 1024px){
                        filter: blur(0px);
                    }
                }
    
                .title{
                    opacity: 1;
                }
                
            }

            
        }

        &__grid-4 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 15px;
            row-gap: 15px;
            @media ($media-mobile){
                display: block;
            }

            &__element{
                width: 100%;
                height: auto;
                position: relative;
                border-radius: 15px;
                display: grid;
                overflow: hidden;
                border-radius: 10px;
                z-index:1;

                @media(max-width: 540px){
                    margin-bottom: 20px;
                }
                .image{
                    overflow: hidden;
                    position: relative; 
                    border-radius: 10px;
                }
                img{
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                    position: relative;
                    display: block;
                }
    
                .title{
                    align-self: end;
                    padding: 10px 10px;
                    opacity: 1;
                    transition: all 0.3s ease;
                    @media(max-width: 1024px){
                        opacity: 1;
                    }
    
                    h3{
                        color: black;
                        font-size: $fz-18;
                        line-height: 17px;
                        font-weight: 500;

                        @media(max-width: 768px){
                            font-size: 16px;
                            line-height: 15px;
                        }
                    }

                    p{
                        opacity: 1;
                        font-size: $fz-18;
                        line-height: 17px;
                        font-weight: 400;
                        color: black;
                        @media(max-width: 768px){
                            font-size: 16px;
                            line-height: 15px;
                        }
                    }
                }
            }
    
            &__element:hover{
                img{
                    filter: blur(4px);
                    @media(max-width: 1024px){
                        filter: blur(0px);
                    }
                }
    
                .title{
                    opacity: 1;
                }
                
            }
        }

        // &__grid-3a {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        //     column-gap: 15px;
        //     row-gap: 30px;
        //     @media ($media-mobile){
        //         display: block;
        //     }
        // }

        // &__grid-3b {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        //     grid-gap: 15px;
        //     @media ($media-mobile){
        //         display: block;
        //     }
        // }

        &__element{
            width: 100%;
            position: relative;
            border-radius: 15px;
            display: grid;
            overflow: hidden;

            .image{
                overflow:hidden;
                border-radius: 15px;
                height: 100%;
            }

            img{
                min-width: 100%;
                height: auto;
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                border-radius: 15px;
                transition: all 0.3s ease;
            }

            .title{
                align-self: end;
                padding: 10px 0px 0px;
                opacity: 0;
                transition: all 0.3s ease;
                position: absolute;
                bottom: 20px;
                left: 15px;
                @media(max-width: 1024px){
                    opacity: 1;
                }

                h3{
                    color: white;
                }
            }

            @media ($media-mobile){
                margin-bottom: 20px;
            }
        }

        &__element:hover{
            img{
                filter: blur(8px);
                @media(max-width: 1024px){
                    filter: blur(0px);
                }
            }

            .title{
                opacity: 1;
            }
            
        }
    }
}