// Basics ----------------
@import "reset";
@import "fonts";
@import "variables";
@import "base";

// Sections ----------------
@import "sections/menu";
@import "sections/footer";
@import "sections/header";
@import "sections/top-header";


// Partials ----------------
@import "partials/buttons";
@import "partials/deco-parallax";


// Templates ----------------
@import "templates/home";
@import "templates/about";
@import "templates/single";
