// Media queries
$media-desktop: "max-width : 1024px";
$media-tablet: "max-width : 990px";
$media-mobile: "max-width : 767px";
$media-mobile-sm: "max-width : 375px";

// Colors
$yellow: #FBCC05;
$green: #68B766;
$lightblue: #C2E4F9;
$blue: #3C5DA7;
$violet: #CFA5CD;
$pink: #F5B7D4;
$red: #E14C17;
$gray: #D8D8D8;


// Spacing
$p-x: clamp(1.5rem, 2.5vw, 30px); 

// Fonts
$f: 'Afflictis', sans-serif;

$medium: 400;
$sbold: 500;
$bold: 600;

// Font-sizes
$fz-18: 18px; 
$fz-25: 25px; 
$fz-35: 35px; 
$fz-60: 60px; 
$fz-100:clamp(4rem, 6vw, 11rem);



