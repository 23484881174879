.top-header{
    &__logo{
        height: 600px;
        margin-top: 30px; 
        svg{
            width: 100%;
        }

        @media ($media-mobile){
           margin-top: 30px; 
           margin-bottom: 30px;
           height: auto;
        }
    }

    &__logos:hover{
        .d-none{
            display: block !important;
        }

        .d-block{
            display: none !important;
        }
    }

    &__text{
        padding: 25px 0;
        border-top: 1px solid #000000;
        font-size: $fz-60;
        line-height: $fz-60;
        /* or 100% */
        letter-spacing: -0.04em;
        margin-bottom: 50px;
        @media(max-width: 768px){
            font-size: 50px;
            line-height: 50px;
        }
        @media(max-width: 540px){
            font-size: 40px;
            line-height: 40px;
        }

        @media(max-width: 470px){
            font-size: 30px;
            line-height: 30px;
        }
        strong{
            opacity: 0.5;
        }

        
        
    }
}