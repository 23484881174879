.nav-index{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 0px;
    width: 100%;
    top: 0px;
    background-color: black;
    position: sticky;
    z-index: 10;

    @media(max-width: 540px){
        padding: 10px 15px 0px;
    }
    
    &__links{
        display: flex;
        align-items: center; 
        font-size: $fz-25;
        justify-content: space-evenly;
        width: 100%;
        padding: 6px 2px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        @media(max-width: 540px){
            font-size: $fz-18;
        }

        @media(max-width: 470px){
            font-size: 16px;
        }
    }

    &__link{
        width: 100%;
        margin-left: 0px;
        padding: 11px 10px;
        text-align: center;
        position: relative;
        cursor: pointer;

        @media(max-width: 540px){
            padding: 11px 5px;
        }

        @media(max-width: 470px){
            padding: 11px 2px;
        }
    }

    &__link:first-child, &__link:nth-child(2), &__link:nth-child(3){
    // &__link:first-child {
        border-right: 1px solid #fff;
    }

    &__link.active:before{
        content: ' \25CF';
        font-size: 25px;
        margin-right: 5px;
        @media($media-mobile){
            font-size: 17px;
        }
    }
}

header {
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    font-size: $fz-25;
    z-index: 11;
    
    img{
        width: 35vw;
        height: auto;
    }
    
    .nav{
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        width: 100%;
        top: 0;
        background-color: white;
        z-index: 10;
        @media($media-mobile){
            padding: 15px 15px;  
        }
        &__logo{
            font-size: 60px;
            @media(max-width: 768px){
                font-size: 50px;
            }
            @media(max-width: 540px){
                font-size: 40px;
            }
            @media(max-width: 470px){
                font-size: 25px;
            }
        }

        &__name{
            align-self: center; 
            font-size: $fz-25;
            text-align: center;
            @media(max-width: 1024px){
                display: none !important;
            }
        }

        &__links{
            display: flex;
            align-items: center; 
            font-size: $fz-25;
            @media(max-width: 470px){
                font-size: 18px;
            }
        }

        &__link{
            margin-left: 8px;
            padding: 10px 10px;
            border: 1px solid #000000;
            min-width: 150px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 60px;
            @media(max-width: 540px){
                padding: 5px 10px;
                min-width: auto;
                font-size: $fz-18; 
            }
        }

        &__link.active{
            background-color: #000000;
            color: white; 
        }

        .lang{
            min-width: auto;
        }
    }
}
