/****************************************************************
/ GENERAL STYLING
/****************************************************************/
html{
    font-size: 62.5%;
}
body {
    margin: 0;
    font-family: $f;
    font-weight: 400;
    line-height: 1.25;
    // font rendering an aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    transition: opacity 0.5s ease;
} 

body.hidden{
    opacity: 0;
}

.p-x{
    padding-left: clamp(1.5rem, 7vw, 40rem);
    padding-right: clamp(1.5rem, 7vw, 40rem);
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p a{
    text-decoration: underline;
}

u{
    text-decoration: underline;
}





/****************************************************************
/ UTILITIES
/****************************************************************/

.lazy-image {
    opacity: 0;
    transition: opacity  0.6s ease;
}

.bg-cover{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contain{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.d-none{
    display: none;
}

