.about{
    background-color: black;

    header{
        background-color: black;
        color: white;

        @media($media-mobile){
            background-color: black;
        }

        .nav{
            background-color: transparent;
            &__logo{
                font-size: 60px;
                opacity: 1;
                @media(max-width: 768px){
                    font-size: 50px;
                }

                @media(max-width: 540px){
                    font-size: 40px;   
                }

                @media(max-width: 470px){
                    font-size: 25px;   
                }

            }
    
            &__links{
                display: flex;
                align-items: center; 
            }
    
            &__link.active{
                background-color: #fff;
                color: black; 
            }
    
            &__link{
                margin-left: 8px;
                padding: 10px 10px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                font-size: 25px;
                text-align: center;
                min-width: 150px;
                box-sizing: border-box;
                border-radius: 60px;

                @media(max-width: 540px){
                    font-size: $fz-18;
                    min-width: auto; 
                    padding: 5px 10px;  
                }

            }

            &__link.lang{
                min-width: auto;
            }
        }
        
    }

    .nav-about{
        position: fixed;
        width: calc(100% - 60px);
        padding: 10px 0px;
        border-top: 0px solid white;
        border-bottom: 1px solid white;
        top: 77px;
        left: 30px;
        background-color: black;
        z-index: 10;
        @media(max-width: 991px){
            display: none;
        }

        &__links{
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            li{
                font-size: $fz-18;
                color: white;
                position: relative;
                font-weight: 500;
                cursor: pointer;
            }

            li.active:before{
                content: ' \25CF';
                font-size: 15px;
                position: absolute;
                margin-right: 5px;
                left: -15px;
                top: 50%;
                transform: translateY(-57%);
                @media($media-mobile){
                    font-size: 17px;
                }
            }
        }

        
    }



    

    

    .template-about{
        color: white;
        padding-top: 120px;
        section{
            @media(max-width: 540px){
                margin-bottom: 20px;
            }
            
        }
        // .top-header{
        //     padding: 90px 0px 0px;

        //     @media($media-mobile){
        //         padding: 50px 0px 0px;
        //     }
        //     &__logo{
        //         height: auto;
        //         padding-bottom: 50px;
        //         @media($media-mobile){
        //             padding-bottom: 0px;
        //         }
        //         svg{
        //             width: 100%;
        //         }
        //     }

        //     &__logo.active{
        //         position: fixed;
        //         top: 0px;
        //         left: 15px;
        //         width: 100px;
        //         display: block;
        //         transition: all 0.3s ease;
        //         margin: 20px 0;
        //         padding: 0;
        //         z-index: 99;
        //     }
        //     &__text{
        //         padding: 25px 0;
        //         border-top: 1px solid rgba(255, 255, 255, 0.5);
        //         font-size: $fz-60;
        //         line-height: $fz-60;
        //         /* or 100% */
        //         letter-spacing: -0.04em;
        //         margin-bottom: 50px;
        //         color: white;
        //         @media($media-mobile){
        //             margin-bottom: 0px;
        //         }
        //         strong{
        //             opacity: 0.5;
        //         }
        //     }
        // }

        &__data.team{
            .five-col{
                .space{
                    @media(max-width: 1024px){
                        display: none;
                    }
                }

                .space-ipad{
                    display: none;
                    @media(max-width: 1024px){
                        display: block;
                    }

                    @media(max-width: 768px){
                        display: none;
                    }
                }

                .space-mobile{
                    display: none;

                    @media(max-width: 768px){
                        display: block;
                    }

                    @media(max-width: 540px){
                        display: none;
                    }
                }
                &__item{
                    color: white;
                    padding: 0px 0;
                    h3{
                        color: white;
                        font-size: 25px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        opacity: 1;
                        height: auto;
                        text-transform: uppercase;
                        margin-top: 0px;
                        @media(max-width: 768px){
                            font-size: 20px;
                        }
                    }

                    .position{
                        font-size: 25px;
                        font-weight: 500;
                        @media(max-width: 768px){
                            font-size: 20px;
                        }
                    }
    
                    p{
                        font-size: 20px;
                        @media(max-width: 768px){
                            font-size: 16px;
                        }
                    }
                }
            }
            
        }

        &__main{

            margin-top: 180px;
            height: 40vh;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            display: grid;
            align-content: center;

            @media(max-width: 1024px){
                margin-top: 170px;
                height: 20vh;            
            }

            @media(max-width: 768px){
                margin-top: 90px;
            }

            .title{
                font-size: 60px;
                color: white;
                width: 60%;
                justify-self: center;
                @media(max-width: 768px){
                    font-size: 50px;
                    width: 80%;
                }

                @media(max-width: 540px){
                    font-size: 40px;
                    width: 100%;
                }

                @media(max-width: 470px){
                    font-size: 35px;
                    width: 100%;
                }
            }
        }

        &__two-col{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 30px;
            margin-top: 50px;
            @media(max-width: 1024px){
                grid-template-columns: 1fr; 
            }

            @media(max-width: 540px){
                grid-template-columns: 1fr;
                margin-top: 30px;
            }

            .content{
                grid-column: 1 / span 2;
                font-size: 40px;
                @media(max-width: 1024px){
                    grid-column:unset; 
                }

                @media(max-width: 768px){
                    font-size: 30px;
                }

                @media(max-width: 470px){
                    font-size: 25px;
                }
                p{
                    margin-bottom: 40px;
                    @media(max-width: 470px){
                        margin-bottom: 20px;
                    }
                }

                p:last-child{
                    margin-bottom: 0;
                }
            }

            .content-image{
                grid-column: 3 / span 3;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                height: 100%;
                width: 100%;
                @media(max-width: 1024px){
                    grid-column:unset; 
                    height: 40vh;
                }

                .image{
                    border-radius: 15px;
                    overflow:hidden;
                    height: auto;
                    img {
                        min-width: 100%;
                        height: auto ;
                        position: relative;
                        top: unset;
                        left: unset; 
                        transform: unset;
                        border-radius: 20px;
                        transition: all 0.3s ease;
                    }
                }
            }

            .space{
                grid-column: 1 / span 2;
                @media(max-width: 1024px){
                    display: none; 
                } 
            }

            .sub-content{
                grid-column: 3 / span 2;
                margin-top: 20px;
                @media(max-width: 1024px){
                    grid-column:unset; 
                }
                p{
                    font-size: 30px;
                    margin-bottom: 40px;
                    @media(max-width: 540px){
                        font-size: 18px;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        

        &__data{
            margin-bottom: 25px;

            .two-col-natural{
                margin-top: 100px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 15px; 

                @media(max-width: 1024px){
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                @media(max-width: 768px){
                    grid-template-columns: 1fr 1fr 1fr;
                }

                @media(max-width: 540px){
                    grid-template-columns: 1fr;
                }

                &__clients{
                    column-count: 4;
                    grid-column: 2 / span 4;
                    @media(max-width: 1024px){
                        column-count: 3;
                        grid-column: 2 / span 3;
                    }
                    @media(max-width: 768px){
                        column-count: 2;
                        grid-column: 2 / span 2;
                    }
                    @media(max-width: 540px){
                        column-count: unset;
                        grid-column: unset;
                    }
                    li{
                        padding: 0;
                        font-size: 20px;
                    }
                }

                .title-item{
                    h3{
                        text-decoration: underline;
                        text-transform: uppercase;
                    }     
                }

                &__item{
                    color: white;
                    padding: 0px 0;
                    h3{
                        color: white;
                        font-size: 25px;
                        margin-bottom: 12px;
                        font-weight: 500;
                        opacity: 1;
                        height: 100px;
                        text-transform: uppercase;
                        @media(max-width: 768px){
                            font-size: 20px;
                            height: 50px;
                        }
                    }

                    

                    p{
                        color: white;
                        font-size: 20px;
                        margin-bottom: 30px;
                    }

                    .content{

                        ul{
                            font-size: 20px;
                            @media(max-width: 540px){
                                font-size: 16px;
                            }
                        }
                    }
                }


            }

            .five-col{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 15px;
                @media(max-width: 1024px){
                    grid-template-columns: 1fr 1fr 1fr 1fr; 
                    grid-row-gap: 30px;
                }

                @media(max-width: 768px){
                    grid-template-columns: 1fr 1fr 1fr; 
                    grid-row-gap: 30px;
                }

                @media(max-width: 540px){
                    display: block;
                    margin-top: 20px;
                }

                .space{
                    @media(max-width: 1024px){
                        display: none;
                    }
                }

                .space-ipad{
                    display: none;
                    @media(max-width: 1024px){
                        display: block;
                    }

                    @media(max-width: 768px){
                        display: none;
                    }
                }

                .space-mobile{
                    display: none;

                    @media(max-width: 768px){
                        display: block;
                    }

                    @media(max-width: 540px){
                        display: none;
                    }
                }

                

                .title-item{
                    @media(max-width: 1024px){
                        grid-column: span 1;
                    }

                    @media(max-width: 540px){
                        grid-column: unset; 
                    }
                    h3{
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                    
                }
                

                @media(max-width: 540px){
                    &__item:nth-child(even){
                        grid-column: unset; 
                    } 
                }

                &__item{
                    color: white;
                    padding: 16px 0;
                    
                    @media(max-width: 540px){
                        grid-column: unset;
                        
                    }
                    h3{
                        color: white;
                        font-size: 25px;
                        margin-bottom: 12px;
                        opacity: 1;
                        height: 100px;
                        font-weight: 500;
                        text-transform: uppercase;
                        @media(max-width: 768px){
                            font-size: 20px;
                            height: 50px;
                        }

                        @media(max-width: 540px){
                            height: auto; 
                            
                        }
                    }

                    

                    p{
                        color: white;
                        font-size: 25px;
                        margin-bottom: 30px;
                        @media(max-width: 768px){
                            font-size: 20px;
                        }
                    }

                    .content{

                        ul{
                            font-size: 20px;
                            @media(max-width: 540px){
                                font-size: 16px;
                            }
                        }
                    }

                    .image{
                        height: 350px;
                        width: 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-bottom: 7px;
                        @media(max-width: 768px){
                            height: 250px;
                        }
                    }

                    

                    
                }

                &__item.sub-content{
                    grid-column: 2 / span 1;
                    @media(max-width: 1024px){
                        grid-column: 2 / span 2;
                    }
                    @media(max-width: 540px){
                        grid-column: unset; 
                        margin-bottom: 40px;
                    }
                }

                .content-image{
                    background-position: center top;
                    background-repeat: no-repeat;
                    background-size: cover;
                    grid-column: 3 / span 3;
                    height: auto;
                    margin-top: 20px;
                    @media(max-width: 768px){
                        height: 40vh;

                    }
                    @media(max-width: 540px){
                        grid-column: unset; 
                    }

                    .image{
                        border-radius: 20px;
                        overflow:hidden;
                        height: auto;
                        img {
                            min-width: 100%;
                            height: auto ;
                            position: relative;
                            top: unset;
                            left: unset; 
                            transform: unset;
                            border-radius: 20px;
                            transition: all 0.3s ease;
                        }
                    }
                }
    
            }

        }

        &__data.team{
            margin-top: 70px;
            @media(max-width: 767px){
                margin-top: 40px;
            }
        }

        &__team{
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            margin-bottom: 80px;
            @media(max-width: 767px){
                margin-top: 40px;
            }
            h3{
                font-size: 25px;
                color: white;
                margin-top: 20px;
                @media(max-width: 768px){
                    font-size: 20px;
                }
            }

            .two-col:nth-child(2){
                border-top: none;
            }

            .two-col{
                display: grid;
                grid-template-columns: 1fr 1fr;

                border-top: 1px solid rgba(255, 255, 255, 0.5);
                padding: 50px 0;
                @media($media-mobile){
                    grid-template-columns: 1fr; 
                }
                &__item{

                    h5{
                        opacity: 0.5;
                        color: white;
                        font-size: 25px;
                        margin-bottom: 0;
                        @media(max-width: 768px){
                            font-size: 20px;
                        }
                    }

                    h3{
                        color: white;
                        font-size: $fz-60;
                        margin: 0;
                    }

                    
                }

                &__description{
                    margin-top: 20px;
                    p{
                        color: white;
                        font-size: 25px;
                        @media(max-width: 768px){
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        
    }
}