.footer{
    background: #000;
    padding: 17px 30px;
    display: grid;
    @media($media-mobile){
        padding: 17px 15px;  
    }
    &__top{
        display: flex;
        justify-content: space-between;
        padding: 5px 0px;
        width: 100%;
        color: white; 
        margin-bottom: 247px;
        @media(max-width: 540px){
            margin-bottom: 123px;
        }
    }

    &__bottom{
        display: grid;
        color: white;
        font-size: $fz-18;
        grid-template-columns: 1fr 1fr 1fr;
        align-self: bottom;

        @media (max-width: 768px){
            font-size: $fz-18;
            grid-template-columns: 1fr;
            text-align: center;
            align-self: bottom;
            grid-row-gap: 20px;
        }

        @media (max-width: 540px){
            font-size: 16px;
            grid-template-columns: 1fr;
            text-align: center;
            align-self: bottom;
            grid-row-gap: 20px;
        }
    }

    &__elem{
        max-width: 250px;
        @media (max-width: 768px){
            max-width: 100%;
            margin: auto;
        }
        h3{
            opacity: 0.5;
        }
    }

    &__logo{
        font-size: $fz-35;
        @media(max-width: 768px){
            font-size: $fz-25;  
        }
    }

    &__links{
        display: flex;
        align-items: center; 
    }

    &__link:first-child{
        border-right: 1px solid #fff;
    }

    &__link:nth-child(2){
        margin-left: 0px;
    }

    &__link{
        margin-left: 8px;
        padding: 0px 10px;
        font-size: $fz-25;
        box-sizing: border-box;
        @media (max-width: 768px){
            padding: 5px 10px;
            font-size: $fz-18;
        }

        @media (max-width: 540px){
            font-size: 16px;
        }
    }

}

.about{
    .footer{
        background: #fff;
        padding: 17px 30px;
        display: grid;
        @media($media-mobile){
            padding: 17px 15px;  
        }
        &__top{
            display: flex;
            justify-content: space-between;
            padding: 5px 0px;
            width: 100%;
            color: black; 
            margin-bottom: 247px;
        }
    
        &__bottom{
            display: grid;
            color: black;
            font-size: $fz-18;
            grid-template-columns: 1fr 1fr 1fr;
            align-self: bottom;
    
            @media (max-width: 768px){
                font-size: $fz-18;
            }
    
            @media (max-width: 540px){
                font-size: 16px;
                grid-template-columns: 1fr;
                text-align: center;
                align-self: bottom;
                grid-row-gap: 20px;
            }
        }
    
        &__elem{
            max-width: 250px;
            @media (max-width: 540px){
                max-width: 100%;
            }
            h3{
                opacity: 0.5;
            }
            
        }
    
        &__logo{
            font-size: $fz-35;
            @media(max-width: 768px){
                font-size: $fz-25;  
            }
        }
    
        &__links{
            display: flex;
            align-items: center; 
        }

        &__link:first-child{
            border-right: 1px solid black;
        }
    
        &__link{
            margin-left: 8px;
            padding: 10px 10px;          
            font-size: $fz-25;
            box-sizing: border-box;
            
            @media (max-width: 768px){
                padding: 5px 10px;
                font-size: $fz-18;
            }
    
            @media (max-width: 540px){
                font-size: 16px;
            }
        }  
    }
}