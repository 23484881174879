@font-face {
    font-family: "Afflictis";
    src: url("../fonts/AfflictisZero-Regular.woff2") format("woff2"),
        url("../fonts/AfflictisZero-Regular.woff") format("woff"),
        url("../fonts/AfflictisZero-Regular.eot") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Afflictis";
    src: url("../fonts/AfflictisZero-Medium.woff2") format("woff2"),
        url("../fonts/AfflictisZero-Medium.woff") format("woff"),
        url("../fonts/AfflictisZero-Medium.eot") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Blow Up';
    src: url('../fonts/BlowUp.eot');
    src: url('../fonts/BlowUp.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BlowUp.woff2') format('woff2'),
        url('../fonts/BlowUp.woff') format('woff'),
        url('../fonts/BlowUp.ttf') format('truetype'),
        url('../fonts/BlowUp.svg#BlowUp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
